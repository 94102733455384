import {
    CUSTOMER_FETCH_REQUEST,
    CUSTOMER_FETCH_SUCCESS,
    CUSTOMER_FETCH_FAIL,
} from "../actions/types"


const initialState = {
    loading:false,
    list: [], 
    totalcustomer:0
}


export const customerReducer = (state = initialState, action) => {
    switch(action.type){
        case CUSTOMER_FETCH_REQUEST:
            return {
                ...state,
                loading:true
            }
        case CUSTOMER_FETCH_SUCCESS:
            return {
                ...state,
                loading:false,
                list: action.payload, 
                totalcustomer:action.payload.length
            }
        case CUSTOMER_FETCH_FAIL:
                return {
                    ...state,
                    loading:false
                }
        default:
            return state
    }
}