import {
    AUTH_REQUEST,
    AUTH_REQUEST_SUCCESS,
    AUTH_REQUEST_FAIL,
    URL,
    LOGOUT_USER,
    JOIN_REQUEST,
    JOIN_REQUEST_SUCCESS,
    JOIN_REQUEST_FAIL
} from "./types"

import axios from "axios"

export const authenticate = (email, password) => async(dispatch) => {
    let user = {
        email,
        password
    }
    try {
        dispatch({ type:AUTH_REQUEST })
        const { data }  = await axios.post(`${URL}user/login`, user)
        // set token localstorage
        localStorage.setItem('token', JSON.stringify(data.token))
        // dispatch success
        dispatch({type:AUTH_REQUEST_SUCCESS, payload:data.user})
        
    } catch (error) {
        dispatch({type:AUTH_REQUEST_FAIL, payload:"invalid credentials"})
    }
}

export const join = (name, phone, email, address, company, password) => async(dispatch) =>  {
    let user = {
        name,
        phone,
        email,
        address,
        company,
        password
    }

    try {
        dispatch({ type:JOIN_REQUEST })
        const { data }  = await axios.post(`${URL}user/register`, user)
        if(data.user){
            // set token localstorage
            localStorage.setItem('token', JSON.stringify(data.token))
            // dispatch success
            dispatch({type:JOIN_REQUEST_SUCCESS, payload:data.user})
        }else{
            dispatch({type:JOIN_REQUEST_FAIL, payload:data.error})
        }
        
    } catch (error) {
        dispatch({type:JOIN_REQUEST_FAIL, payload:"something wrong !"})
    }


}

export const checkAuth = () => async(dispatch) =>  {
    let token = JSON.parse(localStorage.getItem('token'))
    if(!token){
        return false
    }
    let config = {
        headers:{
            'Content-type':'aplication/json',
            'x-auth-token':token
        }
    }
    
    try {
        dispatch({ type:AUTH_REQUEST })
        const { data }  = await axios.get(`${URL}user`, config)
        if(data.authenticated){
            dispatch({type:AUTH_REQUEST_SUCCESS, payload:data.user})
        }else{
            dispatch({type:AUTH_REQUEST_FAIL, payload:"invalid token"})
        }
    } catch (error) {
        dispatch({type:AUTH_REQUEST_FAIL, payload:"server response fail"})
    }

}

export const logout = () => (dispatch) => {
    localStorage.removeItem('token')
    dispatch({type:LOGOUT_USER})
}