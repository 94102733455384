import React, { useEffect, useState } from 'react'
import { join, checkAuth } from '../../redux/actions/authActions'
import {useSelector, useDispatch} from "react-redux"
import { Link } from "react-router-dom"
const Register = ({ history }) => {
    const dispatch = useDispatch()
    
    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [address, setAddress] = useState("")
    const [company, setCompany] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    // Error
    const [hasError, setHasError] = useState(false)
    const [error, setError] = useState("")

    let {loading, hasAuthError, errorMessage, isAuthenticated} = useSelector(state => state.auth)
    
    
    const register = () => {
        if(name===""){
            setHasError(true)
            setError("name feild is required !")
        }else if(phone===""){
            setHasError(true)
            setError("Phone feild is required !")
        }else if(email===""){
            setHasError(true)
            setError("Email feild is required !")
        }else if(address===""){
            setHasError(true)
            setError("name feild is required !")
        }else if(company===""){
            setHasError(true)
            setError("name feild is required !")
        }else if(password===""){
            setHasError(true)
            setError("name feild is required !")
        }else if(password.split("").length < 6){
            setHasError(true)
            setError("password should be grater then 6 charecter !")
        }else if(confirmPassword===""){
            setHasError(true)
            setError("name feild is required !")
        }else if(password !== confirmPassword){
            setHasError(true)
            setError("password not match with confirm password !")
        }else{
            dispatch(join(name, phone, email, address, company, password))
        }
    }

    // Check Authenticate
    useEffect(() => {
        if(isAuthenticated){
            history.push('/')
        }

        dispatch(checkAuth())
    }, [dispatch, history, isAuthenticated]);

    return (
            <div className="row">
                <div className="col-md-8 mt-5 mx-auto">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="display-5 text-center">Create an Account</h3>
                        </div>
                        {hasError && <div className="alert alert-danger">
                            <strong>Error:</strong>
                            {error}
                        </div>}

                        {hasAuthError && <div className="alert alert-danger">
                            <strong>Error : </strong>
                            {errorMessage}
                        </div>}
                        
                        <div className="card-body">
                            <div className="form-group row">
                                <div className="col-md-6">
                                    <label htmlFor="name">Full name <span className="text-danger">*</span></label>
                                    <input onInput={(e) => setName(e.target.value)} type="email" className="form-control" />
                                </div>

                                <div className="col-md-6">
                                    <label htmlFor="phone">Phone<span className="text-danger">*</span></label>
                                    <input onInput={(e) => setPhone(e.target.value)} type="email" className="form-control" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-6">
                                    <label htmlFor="email">Email<span className="text-danger">*</span></label>
                                    <input onInput={(e) => setEmail(e.target.value)} type="email" className="form-control" />
                                </div>

                                <div className="col-md-6">
                                    <label htmlFor="email">Address<span className="text-danger">*</span></label>
                                    <input onInput={(e) => setAddress(e.target.value)} type="email" className="form-control" />
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-6">
                                    <label htmlFor="email">Company Name<span className="text-danger">*</span></label>
                                    <input onInput={(e) => setCompany(e.target.value)} type="email" className="form-control" />
                                </div>

                                <div className="col-md-6">
                                    <label htmlFor="email">password<span className="text-danger">*</span></label>
                                    <input onInput={(e) => setPassword(e.target.value)} type="password" className="form-control" />
                                </div>

                                <div className="col-md-12 mt-2">
                                    <label htmlFor="email">confirm password<span className="text-danger">*</span></label>
                                    <input onInput={(e) => setConfirmPassword(e.target.value)} type="password" className="form-control" />
                                </div>
                            </div>
                            
                            <div className="form-group">
                                <button disabled={loading} onClick={register} className="btn btn-dark btn-block"> 
                                {loading? 'joining ...': 'Join'}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer text-center">
                        Sign in instead ? <Link to="/authenticate">sign in</Link>
                    </div>
                </div>
            </div>
    )
}

export default Register
