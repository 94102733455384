import React from 'react'
import { useSelector } from 'react-redux'
import { ListGroup,Badge } from "react-bootstrap"
import { Link } from "react-router-dom"
const Profile = () => {

    let { name, email, phone, company,address, isPhoneVerified }  = useSelector(state => state.auth)

    return (
        <div className="row">
            <div className="card col-md-6 mx-auto mt-2">
                <div className="card-header">
                    <h4>Profile information</h4>
                </div>
                <div className="card-body">
                    <ListGroup as="ul">
                        <ListGroup.Item as="li">
                            <strong>Name</strong> <span style={{float:'right'}}>{name}</span>
                        </ListGroup.Item>
                        <ListGroup.Item as="li">
                            <strong>Email</strong> <span style={{float:'right'}}>{email}</span>
                        </ListGroup.Item>
                        <ListGroup.Item as="li">
                            <strong>Phone</strong> <span style={{float:'right'}}>

                                {isPhoneVerified && <Badge variant="success">verified </Badge>}
                            

                            {''} {phone}</span>
                        </ListGroup.Item>
                        <ListGroup.Item as="li">
                            <strong>Address</strong> <span style={{float:'right'}}>{address}</span>
                        </ListGroup.Item>
                        <ListGroup.Item as="li">
                            <strong>Company </strong> <span style={{float:'right'}}>{company}</span>
                        </ListGroup.Item>
                        <ListGroup.Item as="li">
                            <Link to="/change-password" className="btn btn-dark btn-block">Change Password</Link>
                        </ListGroup.Item>
                    </ListGroup>

                </div>
            </div>
        </div>
    )
}

export default Profile
