import {
    REMITTANCE_CLIENT_REQUEST,
    REMITTANCE_CLIENT_REQUEST_SUCCESS,
    REMITTANCE_CLIENT_REQUEST_FAIL
} from "../actions/types"


const initialState = {
    loading:false,
    list: [], 
    totalClient:0
}


export const remittanceReducer = (state = initialState, action) => {
    switch(action.type){
        case REMITTANCE_CLIENT_REQUEST:
            return {
                ...state,
                loading:true
            }
        case REMITTANCE_CLIENT_REQUEST_SUCCESS:
            return {
                ...state,
                loading:false,
                list: action.payload, 
                totalClient:action.payload.length
            }
        case REMITTANCE_CLIENT_REQUEST_FAIL:
                return {
                    ...state,
                    loading:false
                }
        default:
            return state
    }
}