import React, { useEffect, useState } from 'react'
import { authenticate, checkAuth } from '../../redux/actions/authActions'
import {useSelector, useDispatch} from "react-redux"
import { Link } from "react-router-dom"
const Login = ({ history }) => {
    const dispatch = useDispatch()
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    // Error
    const [hasError, setHasError] = useState(false)
    const [error, setError] = useState("")

    let {loading, hasAuthError, errorMessage, isAuthenticated} = useSelector(state => state.auth)
    
    console.log(hasAuthError)
    console.log(errorMessage)

    const login = () => {
        if(email==="" || password === ""){
            setHasError(true)
            setError("field must not be empty !")
        }else{
            dispatch(authenticate(email, password))
        }
    }

    // Check Authenticate
    useEffect(() => {
        if(isAuthenticated){
            history.push('/')
        }

        dispatch(checkAuth())
    }, [dispatch, history, isAuthenticated]);

    return (
            <div className="row">
                <div className="col-md-4 mt-5 mx-auto">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="display-4 text-center">Please Login</h3>
                        </div>
                        {hasError && <div className="alert alert-danger">
                            <strong>Error:</strong>
                            {error}
                        </div>}
                        {hasAuthError && <div className="alert alert-danger">
                            <strong>Error:</strong>
                            {errorMessage}
                        </div>}
                        
                        <div className="card-body">
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input onInput={(e) => setEmail(e.target.value)} type="email" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">password</label>
                                <input onInput={(e) => setPassword(e.target.value)} type="password" className="form-control" />
                            </div>
                            <div className="form-group">
                                <button disabled={loading} onClick={() => login()} className="btn btn-dark btn-block"> 
                                {loading? 'authenticating ...': 'Sign in'}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer text-center">
                        Need an account ? <Link to="/join">Join</Link>
                    </div>
                </div>
            </div>
    )
}

export default Login
