import React from 'react'
import {Navbar, Nav, NavDropdown} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux"
import { logout } from "../../redux/actions/authActions"
const Header = () => {
  const dispatch = useDispatch()

  let { isPhoneVerified } = useSelector(state => state.auth)
  
  const handlelogout = () => {
    dispatch(logout())
  }

  return (
    <div className="">
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Navbar.Brand as={Link} to="/">SAFELER-BCMS</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            
          {isPhoneVerified && 
            <Nav.Link as={Link} to="/">Home</Nav.Link>
          }

            {isPhoneVerified && 
            <NavDropdown title="Customer" id="collasible-nav-dropdown">
              <NavDropdown.Item as={Link} to="/customer-add">Add Customer</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/customer-list">Customer List</NavDropdown.Item>
            </NavDropdown>
            }
            
            {isPhoneVerified && 
            <NavDropdown title="Remittance" id="collasible-nav-dropdown">
              <NavDropdown.Item as={Link} to="/remittance-add">Add Remittance Client</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/remittance-list">Remittance Client List</NavDropdown.Item>
            </NavDropdown>
            }



            <NavDropdown title="Profile" id="collasible-nav-dropdown">
              <NavDropdown.Item as={Link} to="/profile">Profile</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={() => handlelogout()}>Logout</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  )
}

export default Header
