import React, {useEffect, useState} from 'react'
import axios from "axios";
import { URL } from '../../../redux/actions/types';
import { Spinner , ListGroup} from "react-bootstrap"
const CustomerView = ({ match }) => {
    let customerid = match.params.id

    const [customer, setCustomer] = useState({})
    const [loading, setLoading] = useState(false);
    // const [error, setError] = useState(null)

    const fetchCustomerById = async() => {
            setLoading(true)
            let { data }  = await axios.get(`${URL}customer/${customerid}`)

            if(data.error){
                //  handle
                // setError('something went wrong !')
            }else{
                setLoading(false)
                setCustomer(data.customer)
                console.log(data.customer)
            }
    }

    useEffect(() => {
        fetchCustomerById()
        // eslint-disable-next-line
    }, [])
    
    return (
        <div className="container">
            
            {loading ?
            <div className="row">
                <div className="col-md-4 mx-auto mt-5">
                 <Spinner animation="border" variant="success" />
                </div>
            </div>
            :
        <div>
            <div className="col-md-8 mx-auto">
                <div className="card">
                    <div className="card-header text-center">
                        <h3>Details of <span className="text-primary">{customer.name}</span> </h3>
                    </div>
                    <div className="card-body">
                    <ListGroup as="ul">
                        <ListGroup.Item as="li">Phone : {customer.phone}</ListGroup.Item>
                        <ListGroup.Item as="li">Account Number : {customer.account} </ListGroup.Item>
                    </ListGroup>
                    <div className="row">
                        {customer.idCard && <div className="col-md-4">
                            <h4>Customer Id Card</h4>
                            <img style={{width:'100%',height:'auto'}} src={customer.idCard} alt="" />
                            <a href={customer.idCard} target="blank" className="btn btn-dark btn-sm btn-block">view</a>
                        </div>}
                        
                        {customer.nomineeIdCard && 
                        <div className="col-md-4">
                            <h4>Nominee ID Card</h4>
                            <img style={{width:'100%',height:'auto'}} src={customer.nomineeIdCard} alt="" />
                            <a href={customer.nomineeIdCard} target="blank" className="btn btn-dark btn-sm btn-block">view</a>
                        </div>
                        }
                        
                        {customer.photoId && 
                        <div className="col-md-4">
                            <h4>Customer Photo ID</h4>
                            <img style={{width:'100%',height:'auto'}} src={customer.photoId} alt="" />
                            <a href={customer.photoId} target="blank" className="btn btn-dark btn-sm btn-block">view</a>
                        </div>}
                        

                        {customer.signCard && 
                        <div className="col-md-4">
                            <h4>Customer Sign Card</h4>
                            <img style={{width:'100%',height:'auto'}} src={customer.signCard} alt="" />
                            <a href={customer.signCard} target="blank" className="btn btn-dark btn-sm btn-block">view</a>
                        </div>}
                        
                    </div>
                    </div>
                </div>
            </div>
        </div>}
            
        </div>
    )
}

export default CustomerView
