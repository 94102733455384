import React from 'react'
import { Spinner } from "react-bootstrap"
import { Link } from "react-router-dom"
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next" 
import ToolkitProvider from 'react-bootstrap-table2-toolkit';

import { useSelector } from 'react-redux';

// const { SearchBar } = Search;

const CustomerList = () => {

    let customers = useSelector(state => state.customer.list)
    let processing = useSelector(state => state.customer.loading)

    const MySearch = (props) => {
        let input;
        const handleClick = () => {
          props.onSearch(input.value);
        };
        return (
          <div>
            <input
              className="form-control"
            //   style={ { backgroundColor: 'pink' } }
              ref={ n => input = n }
              type="text"
              onInput={handleClick}
            />
          </div>
        );
      };

    const actionsFormatter = (cell, row) =>  {
        // console.log(row._id)
        return (
            <React.Fragment>
                <Link className="btn btn-dark mr-3 btn-sm" to={`/customer-edit/${row._id}`}>Edit</Link>
                <Link className="btn btn-dark btn-sm" to={`/customer-view/${row._id}`}>view</Link>
            </React.Fragment>
        )
    }


    // set columns for table
    const columns = [
        {dataField:"name", text:"Full name"},
        {dataField:"phone", text:"Phone Number"},
        {dataField:"account", text:"account"},
        {
         dataField:"_id", 
         text:"",
         isDummyField: true, 
         csvExport: false,
         formatter:actionsFormatter,
         classes: 'text-center'
        }
    ]

    return (
        <div className="row text-center">
            <div className="col-md-8 mx-auto">
                {processing?
                <div className="row">
                    <div className="col-md-4 mx-auto text-center mt-5">
                    <Spinner animation="border" variant="success" />
                    </div>
                </div>
                :

                <ToolkitProvider

                keyField = '_id'
                data={customers}
                columns={columns}
                pagination = {paginationFactory()}

                search
                >
                    {
                        props => (
                        <div className="mt-3">
                            <MySearch { ...props.searchProps } />
                            <BootstrapTable
                            { ...props.baseProps }
                            />
                        </div>
                        )
                    }
                    </ToolkitProvider>
                }

            </div>
        </div>
    )
}

export default CustomerList
