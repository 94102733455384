import axios from "axios";

import {
    CUSTOMER_FETCH_REQUEST,
    CUSTOMER_FETCH_SUCCESS,
    CUSTOMER_FETCH_FAIL,
    URL,    
} from "./types"

let token ;
if(isJson(localStorage.getItem('token'))){
    token = JSON.parse(localStorage.getItem('token'))
}else{
    token = null
}



let config = {
    headers:{
        'Content-type':'aplication/json',
        // 'x-auth-token':token
    }
}

if(token){
    config.headers['x-auth-token'] = token
}


export const fetchAllCustomer = (userid) => async(dispatch) => {
    try {
        dispatch({ type:CUSTOMER_FETCH_REQUEST })
        const { data }  = await axios.post(`${URL}customer`, {userid})
        if(data.customers){
            dispatch({type:CUSTOMER_FETCH_SUCCESS, payload:data.customers})
        }else{
            dispatch({type:CUSTOMER_FETCH_FAIL, payload:"something went wrong"})
        }
    } catch (error) {
        dispatch({type:CUSTOMER_FETCH_FAIL, payload:"server response fail"})
    }
}

function isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}