import { combineReducers } from 'redux'
import { authReducer } from './authReducer'
import { customerReducer } from './customerReducer'
import { remittanceReducer } from './remittanceReducer'

const root = combineReducers({
    customer:customerReducer,
    auth:authReducer,
    remittance:remittanceReducer
})

export default root