import React, {useState} from 'react'

import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { URL, VERIFIED } from '../../redux/actions/types'
const Verify = ({ history }) => {

    const dispatch = useDispatch()

    const { phone } = useSelector(state => state.auth)
    const [sending, setSending] = useState(false)
    const [send, setSend] = useState(false)
    const [sendOnce, setSendOnce] = useState(false)
    const { userid }  = useSelector(state => state.auth)
    const [otp, setOtp] = useState("")
    const [confirming, setConfirming] = useState(false)
    
    const sendOTP = async() => {
        // otp send
        setSending(true)
        const { data }  = await axios.post(`${URL}user/sendotp`, {userid})
        setSending(false)
        if(data.send){
            setSend(true)
        }else{
            // print something went wrong !
        }
        
        // then
        setTimeout(() => {
            setSendOnce(true)
            setSend(false)
        }, 660000);

    }

    const [errorMsg, setErrorMsg] = useState("");
    const [hasError, setHasError] = useState(false);

    const [success, setSuccess] = useState(false)



    const confirm = async() => {
        if(otp === "" || otp.split("").length < 4) {
            // Error
        }else{
            setConfirming(true)
            let {data} = await axios.post(`${URL}user/confirm`, {otp,userid})
            setConfirming(false)
           if(data.success){
            // success
            setSuccess(true)
            dispatch({type:VERIFIED})
            history.push('/')
           }else{
               // otp not valid
               setHasError(true)
               setErrorMsg(data.msg)
               setSuccess(false)
           }
        }
    }
    

    return (
        <div className="row">
            <div className="col-md-6 mt-5 mx-auto">
                <div className="card">
                    {hasError && <div className="alert alert-danger">
                    <strong>Error : </strong> {errorMsg}
                    </div>}

                    {success && <div className="alert alert-success">
                    <strong>success : </strong> phone verified successfully
                    </div>}
                    
                    
                    <div className="card-header">
                        <h3>Verify Your Phone : { phone }</h3>
                    </div>

                {!send && 
                    <div className="form-group">
                        <button onClick={sendOTP} disabled={sending} className="btn btn-dark btn-block">
                        {sending ?"sending . . . ":sendOnce?"Resend OTP":"Send OTP"}
                        </button>
                    </div>}
                    
                
                {send && 
                <> 
                    <div className="form-group">
                        <input onInput={(e) => setOtp(e.target.value)} className="form-control" />
                    </div>

                    <div className="form-group">
                        <button disabled={confirming} onClick={confirm} className="btn btn-dark btn-block">
                            {confirming?"verifying . . .":"verify"}
                        </button>
                    </div>
                </>
                }
                    

                </div>
            </div>
        </div>
    )
}

export default Verify
