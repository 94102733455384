import React, { useEffect } from 'react'
import { Spinner } from "react-bootstrap"
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next" 
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllclients} from "../../../redux/actions/remittanceActions"

const RemittanceList = () => {
    // Take All Remittance Client
    const dispatch = useDispatch()
    const { list, loading } = useSelector(state => state.remittance)
    const { userid }  = useSelector(state => state.auth)

    useEffect(() => {
        dispatch(fetchAllclients(userid))
    }, [dispatch, userid])

    const MySearch = (props) => {
        let input;
        const handleClick = () => {
          props.onSearch(input.value);
        };
        return (
          <div>
            <input
              className="form-control"
            //   style={ { backgroundColor: 'pink' } }
              ref={ n => input = n }
              type="text"
              onInput={handleClick}
            />
          </div>
        );
      };
      

      const actionsFormatter = (cell, row) =>  {
            return (
                <React.Fragment>
                    {row.idCard=== undefined ? <span>not found</span>:
                    <a target="blank" href={row.idCard}>
                    <img style={{width:"100px",height:"auto",maxWidth:"100px"}} src={row.idCard} alt="id card" />
                    </a>
                    }
                </React.Fragment>
            )
        }
            // set columns for table
    const columns = [
        {dataField:"name", text:"Full name"},
        {dataField:"phone", text:"Phone Number"},
        {
         dataField:"_id", 
         text:"",
         isDummyField: true, 
         csvExport: false,
         formatter:actionsFormatter,
         classes: 'text-center'
        }
    ]

    return (
        <React.Fragment>

        {loading ? 
        <div className="row">
            <div className="col-md-4 mx-auto text-center mt-5">
            <Spinner animation="border" variant="success" />
            </div>
        </div>:
        <div className="row">
        <div className="card col-md-8 mx-auto">
            <ToolkitProvider
            keyField = '_id'
            data={list}
            columns={columns}
            pagination = {paginationFactory()}

            search
            >
                {
                    props => (
                    <div className="mt-3">
                        <MySearch { ...props.searchProps } />
                        <BootstrapTable
                        { ...props.baseProps }
                        />
                    </div>
                    )
                }
                </ToolkitProvider>
            </div>
            </div>}
        
        </React.Fragment>
    )
}

export default RemittanceList
