import React, { useState } from 'react'
import { storage } from "./../../../base";
import { Form, ProgressBar,Spinner } from "react-bootstrap";
import moment from "moment";
import { useSelector } from 'react-redux';
import axios from "axios";
import { URL } from '../../../redux/actions/types';
const RemittanceAdd = () => {

    let userid = useSelector(state => state.auth.userid)

    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")
    const [idcard, setIdcard] = useState(null)

    const [uploading, setUploading] = useState(false)
    const [prograse, setPrograse] = useState(0)
    const [submitting, setSubmitting] = useState(false)

    const handleUpload = (file, filename) => {
        const upload =  storage.ref(`${userid}/${filename}`).put(file)
        upload.on('state_changed', snapshot => {
            const prograse = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            )
            setPrograse(prograse)
        }, error => {
            console.log(error)
        }, () => {
            storage.ref(userid)
            .child(filename)
            .getDownloadURL()
            .then(url => {
                setUploading(false)
                setPrograse(0)
                setIdcard(url)
            })
        })
     }

     const [error, setError] = useState(false);
     const [msg, setMsg] = useState("");

     const [success, setisSuccess] = useState(false)
     const [SuccessMsg, setSuccessMsg] = useState("")

     const submit = async() => {
         if(name === ""){
            setError(true)
            setMsg("name must not be empty")
         }else if(phone === ""){
            setError(true)
            setMsg("phone number must not be empty")
         }else if(idcard === null){
            setError(true)
            setMsg("please select client id card")
         }else{
             // submit
             let info = {
                 name,
                 phone,
                 idcard,
                 userid
             }
             setSubmitting(true)
            const { data }  = await axios.post(`${URL}remittance/add`, info)
            setSubmitting(false)
            if(data.success){
                setisSuccess(true)
                setSuccessMsg("Remittance add successfully !")
                setError(false)

                // reset form data
                setName("");
                setPhone("")
                setIdcard(null)
            }else{
                setError(true)
                setMsg(data.error)
                setisSuccess(false)
            }
         }
     }

    return (
        <div className="row ">
            <div className="col-md-8 mx-auto mt-3">
                <div className="card">
                    <div className="card-header">
                        <h4>Remittance Client Add</h4>
                    </div>
                    {uploading && 
                    <div>
                        <ProgressBar striped variant="success" now={prograse} />
                    </div>}
                    
                    {error && 
                    <div className="alert alert-danger">
                        <strong>Error : </strong>{msg}
                    </div>}

                    {success && 
                    <div className="alert alert-success">
                        <strong>Error : </strong>{SuccessMsg}
                    </div>}
                    
                    
                    <div className="card-body">
                        <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <Form.Control value={name} onInput={(e)=> setName(e.target.value)} className="form-control" disabled={uploading} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="name">Phone</label>
                            <Form.Control value={phone} onInput={(e)=> setPhone(e.target.value)} className="form-control" disabled={uploading} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="name">National ID Card</label>
                            <Form.File 
                                disabled={uploading}
                                id="custom-file"
                                label={idcard?"Upload Done":"Please select ID Card"}
                                custom
                                onChange={(e) => {
                                    let file = e.target.files[0];
                                    let filename = moment.now()+"."+file.name.substring(file.name.lastIndexOf('.')+1, file.name.length) || file.name;
                                    // Enable Loading
                                    setUploading(true)
                                    handleUpload(file, filename)
                                }}
                            />
                        </div>

                        <div className="form-group">
                            <button disabled={uploading || submitting} onClick={submit} className="btn btn-dark btn-block">
                                {submitting? 
                                <>
                                    <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    />
                                    <span className="sr-only"> processing...</span>
                                </>:"Add Remittance client"}</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default RemittanceAdd
