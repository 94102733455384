import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {fetchAllCustomer} from "../redux/actions/customerActions"
import { fetchAllclients } from "../redux/actions/remittanceActions"
const Home = ({ history }) => {

    let dispatch = useDispatch()
    let { totalcustomer } = useSelector(state => state.customer)
    let { totalClient } = useSelector(state => state.remittance)
    let { isPhoneVerified } = useSelector(state => state.auth)
    
    // take userid
    let { userid }  = useSelector(state => state.auth)

    useEffect(() => {
        if(!isPhoneVerified){
            history.push('/verify')
        }

        dispatch(fetchAllCustomer(userid))
        dispatch(fetchAllclients(userid))
    }, [dispatch, userid, isPhoneVerified, history])

    return (
        <div className="row text-center mt-4">
            <div className="card col-md-6 mx-auto">
                <div className="card-header">
                    <h2>Dashboard</h2>
                </div>
                <div className="card-body">
                    <h3>Total Client : {totalcustomer}</h3>
                    <h3>Total Remittance : { totalClient }</h3>
                </div>
            </div>
        </div>
    )
}

export default Home
