import React, { useState, useEffect } from 'react'
import { storage } from "./../../../base";
import moment from "moment";
import { Form, ProgressBar,Spinner } from "react-bootstrap"
import { useSelector, useDispatch } from 'react-redux';
import axios from "axios"
import { URL } from '../../../redux/actions/types';
import { fetchAllCustomer } from "../../../redux/actions/customerActions"
const CustomerEdit = ({ match }) => {

    const dispatch = useDispatch()
    let customerid = match.params.id

    // Total files
    const [accHolderIdCard, setAccHolderIdCard] = useState(null)
    const [signatureCard, setSignatureCard] = useState(null)
    const [photoId, setPhotoId] = useState(null)
    const [nomineeIdCard, setNomineeIdCard] = useState(null)
    const [fullname, setFullname] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [Phone, setPhone] = useState("")
    

    const [uploading, setUploading] = useState(false)
    const [prograse, setPrograse] = useState(0)
    const [submitting, setSubmitting] = useState(false)
    // take userid from redux
    let userid =  useSelector(state => state.auth.userid)

    const [hasFormError, setHasFormError] = useState(false)
    const [formErrorMsg, setFormErrorMsg] = useState("")


    const [isSuccess, setisSuccess] = useState(false);
    const [successMsg, setSuccessMsg] = useState("")

    const submit = async () => {

        // Condition for Rendaring
        if(fullname===""){
            setHasFormError(true)
            setFormErrorMsg("Name must not me Empty !")
        }else if(Phone.split("").length > 11 ||  Phone.split("").length < 11){
            setHasFormError(true)
            setFormErrorMsg("Phone number must be 11 digit !")
        }else{
            let info = {
                userid,
                accHolderIdCard,
                signatureCard,
                photoId,
                nomineeIdCard,
                fullname,
                accountNumber,
                Phone,
                customerid
            }
    
            
            setSubmitting(true)
            const { data }  = await axios.post(`${URL}customer/update`, info)
            setSubmitting(false)
            if(data.success){
                setisSuccess(true)
                setSuccessMsg("customer Updated successfully !")
                setHasFormError(false)
                // dispatch fetch all customer
                dispatch(fetchAllCustomer(userid))

            }else{
                setHasFormError(true)
                setFormErrorMsg(data.error)
                setisSuccess(false)
            }
        }
    }

    const handleUpload = (file, filename,  settar) => {
       const upload =  storage.ref(`shadesh/${filename}`).put(file)
       upload.on('state_changed', snapshot => {
           const prograse = Math.round(
               (snapshot.bytesTransferred / snapshot.totalBytes) * 100
           )
           setPrograse(prograse)
       }, error => {
           console.log(error)
       }, () => {
           storage.ref("shadesh")
           .child(filename)
           .getDownloadURL()
           .then(url => {
               setUploading(false)
               setPrograse(0)
                settar(url)
           })
       })
    }

    const [loading, setLoading] = useState(false);

    const fetchCustomerById = async() => {
        setLoading(true)
        let { data }  = await axios.get(`${URL}customer/${customerid}`)

            if(data.error){
                //  handle
                // setError('something went wrong !')

            }else{
                console.log(data.customer)
                setAccHolderIdCard(data.customer.idCard)
                setSignatureCard(data.customer.signCard)
                setPhotoId(data.customer.photoId)
                setNomineeIdCard(data.customer.nomineeIdCard)
                setFullname(data.customer.name)
                setAccountNumber(data.customer.account)
                setPhone(data.customer.phone)
                setLoading(false)
            }
    }


    useEffect(() => {
        fetchCustomerById()
        // eslint-disable-next-line
    }, [])

    return (
        <React.Fragment>
        {loading && 
            <div className="row">
                <div className="card">
                <Spinner animation="border" variant="success" />
                </div>
            </div>
        }
        <div className="row">
            <div className="col-md-6">

                <div className="card">
                    <div className="card-header">
                        <h3>Modify customer Information</h3>
                    </div>
                    {uploading && 
                    <div>
                        <ProgressBar striped variant="success" now={prograse} />
                    </div>}
                    

                    <div className="card-body">
                    

                    {hasFormError && <div className="alert alert-danger"><strong>Error: </strong> {formErrorMsg}</div>}
                    {isSuccess && <div className="alert alert-success"><strong>Success : </strong>{successMsg}</div>}
                    
                        <div className="form-group">
                            <label htmlFor="name">Full name</label>
                            <Form.Control value={fullname} onInput={(e)=> setFullname(e.target.value)} className="form-control" disabled={uploading} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="name">Account Number</label>
                            <Form.Control value={accountNumber} onInput={(e)=> setAccountNumber(e.target.value)} className="form-control" disabled={uploading} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="name">Phone</label>
                            <Form.Control value={Phone} onInput={(e)=> setPhone(e.target.value)} className="form-control" disabled={uploading} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="name">Account Holder ID Card</label>
                            <Form.File 
                                disabled={uploading}
                                id="custom-file"
                                label={accHolderIdCard?"Upload Done":"Select Account Holder image"}
                                custom
                                onChange={(e) => {
                                    let file = e.target.files[0];
                                    let filename = moment.now()+"."+file.name.substring(file.name.lastIndexOf('.')+1, file.name.length) || file.name;
                                    // Enable Loading
                                    setUploading(true)
                                    handleUpload(file, filename, setAccHolderIdCard)
                                }}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="name">Signature Card</label>
                            <Form.File 
                                disabled={uploading}
                                id="custom-file"
                                label= {signatureCard?"Upload Done":"Select signature card"}
                                custom
                                onChange={(e) => {
                                    let file = e.target.files[0];
                                    let filename = moment.now()+"."+file.name.substring(file.name.lastIndexOf('.')+1, file.name.length) || file.name;
                                    // Enable Loading
                                    setUploading(true)
                                    handleUpload(file, filename, setSignatureCard)
                                }}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="name">Photo ID</label>
                            <Form.File 
                                disabled={uploading}
                                id="custom-file"
                                label={photoId?"Upload Done":"Select Photo ID"}
                                custom
                                onChange={(e) => {
                                    let file = e.target.files[0];
                                    let filename = moment.now()+"."+file.name.substring(file.name.lastIndexOf('.')+1, file.name.length) || file.name;
                                    // Enable Loading
                                    setUploading(true)
                                    handleUpload(file, filename, setPhotoId)
                                }}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="name">ID Card (Nominee)</label>
                            <Form.File 
                                disabled={uploading}
                                id="custom-file"
                                label={nomineeIdCard?"Upload Done":"Select Nominee ID Card"}
                                custom
                                onChange={(e) => {
                                    let file = e.target.files[0];
                                    let filename = moment.now()+"."+file.name.substring(file.name.lastIndexOf('.')+1, file.name.length) || file.name;
                                    // Enable Loading
                                    setUploading(true)
                                    handleUpload(file, filename, setNomineeIdCard)
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <button disabled={uploading || submitting} onClick={submit} className="btn btn-dark btn-block">
                                {submitting? <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                />:"Update Customer"}</button>
                        </div>
                    </div>
                </div>
            </div>

            
            <div className="col-md-6">
                <div className="row">
                    {accHolderIdCard && 
                    <div className="col-md-6 card">
                        <p className="card-header">Account Holder ID Card</p>
                        <img style={{width:"100%", height:"auto",maxWidth:'200px'}} src={accHolderIdCard} alt="" />
                    </div>}
                    
                    {signatureCard && 
                    <div className="col-md-6 card">
                        <p className="card-header">Signature Card</p>
                        <img style={{width:"100%", height:"auto",maxWidth:'200px'}} src={signatureCard} alt="" />
                    </div>}
                    
                    {photoId && 
                    <div className="col-md-6 card">
                        <p className="card-header">Photo ID</p>
                        <img style={{width:"100%", height:"auto",maxWidth:'200px'}} src={photoId} alt="" />
                    </div>}
                    
                    
                    {nomineeIdCard && 
                    <div className="col-md-6 card">
                        <p className="card-header">Nominee ID Card</p>
                        <img style={{width:"100%", height:"auto",maxWidth:'200px'}} src={nomineeIdCard} alt="" />
                    </div>}
                    

                </div>
            </div>
        </div>
    </React.Fragment>
    )
}

export default CustomerEdit
