
import {
    AUTH_REQUEST,
    AUTH_REQUEST_SUCCESS,
    AUTH_REQUEST_FAIL,
    LOGOUT_USER,
    JOIN_REQUEST,
    JOIN_REQUEST_SUCCESS,
    JOIN_REQUEST_FAIL,
    VERIFIED
} from "../actions/types"
const initialState = {
    isAuthenticated:false,
    name:null,
    username:null,
    email:null,
    userid:null,
    loading:false,
    errorMessage:null,
    hasAuthError:false,
    isPhoneVerified:false,
    address:null,
    phone:null,
    company:null
}
export const authReducer = (state=initialState, action) => {
    switch(action.type){
        case AUTH_REQUEST:
            return{
                ...state,
                loading:true
            }

        case JOIN_REQUEST:
            return{
                ...state,
                loading:true
            }

        case AUTH_REQUEST_SUCCESS:
            return {
                ...state,
                isAuthenticated:true,
                loading:false,
                hasAuthError:false,
                name:action.payload.name?action.payload.name:null,
                username:action.payload.username?action.payload.username:null,
                email:action.payload.email?action.payload.email:null,
                userid:action.payload.id?action.payload.id:null,
                isPhoneVerified:action.payload.phoneVerified?action.payload.phoneVerified:false,
                address:action.payload.address?action.payload.address:null,
                phone:action.payload.phone?action.payload.phone:null,
                company:action.payload.company?action.payload.company:null
            }
        case JOIN_REQUEST_SUCCESS:
            return {
                ...state,
                isAuthenticated:true,
                loading:false,
                hasAuthError:false,
                name:action.payload.name?action.payload.name:null,
                username:action.payload.username?action.payload.username:null,
                email:action.payload.email?action.payload.email:null,
                userid:action.payload.id?action.payload.id:null,
                isPhoneVerified:action.payload.phoneVerified?action.payload.phoneVerified:false,
                address:action.payload.address?action.payload.address:null,
                phone:action.payload.phone?action.payload.phone:null,
                company:action.payload.company?action.payload.company:null
            }
        case AUTH_REQUEST_FAIL:
            return {
                ...state,
                loading:false,
                hasAuthError:true,
                errorMessage:action.payload,
                isAuthenticated:false,
                name:null,
                username:null,
                email:null,
                userid:null
            }
        case JOIN_REQUEST_FAIL:
            return {
                ...state,
                loading:false,
                hasAuthError:true,
                errorMessage:action.payload,
                isAuthenticated:false,
                name:null,
                username:null,
                email:null,
                userid:null
            }
        
        case LOGOUT_USER:
            return {
                ...state,
                isAuthenticated:false,
                loading:false,
                name:null,
                username:null,
                email:null,
                userid:null,
                hasAuthError:false,
                errorMessage:'successfully loged out'
            }
        case VERIFIED:
            return {
                ...state,
                isPhoneVerified:true
            }

        default:
            return state;
    }
}