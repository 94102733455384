import axios from "axios";

import {
    REMITTANCE_CLIENT_REQUEST,
    REMITTANCE_CLIENT_REQUEST_SUCCESS,
    REMITTANCE_CLIENT_REQUEST_FAIL,
    URL,    
} from "./types"


let token ;
if(isJson(localStorage.getItem('token'))){
    token = JSON.parse(localStorage.getItem('token'))
}else{
    token = null
}


let config = {
    headers:{
        'Content-type':'aplication/json',
        // 'x-auth-token':token
    }
}

if(token){
    config.headers['x-auth-token'] = token
}

export const fetchAllclients = (userid) => async(dispatch) => {
    try {
        dispatch({ type:REMITTANCE_CLIENT_REQUEST })
        const { data }  = await axios.post(`${URL}remittance`,{userid:userid})
        if(data.clients){
            dispatch({type:REMITTANCE_CLIENT_REQUEST_SUCCESS, payload:data.clients})
        }else{
            dispatch({type:REMITTANCE_CLIENT_REQUEST_FAIL, payload:"something went wrong"})
        }
    } catch (error) {
        dispatch({type:REMITTANCE_CLIENT_REQUEST_FAIL, payload:"server response fail"})
    }
}

function isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}