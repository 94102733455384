import React, { useState } from 'react'
import axios from "axios"
import { URL } from '../../redux/actions/types'
import { useSelector } from 'react-redux'
const Changepass = () => {

    const [oldPass, setOldPass] = useState("")
    const [newpass, setNewpass] = useState("")
    const [renewpass, setRenewpass] = useState("")

    // userid
    let { userid }  =  useSelector(state => state.auth)
    // error
    const [hasError, setHasError] = useState(false)
    const [error, setError] = useState("")

    const [success, setSuccess] = useState(false)

    const [processing, setProcessing] = useState(false)

    const changepass = async() => {
        if(oldPass===""){
            setHasError(true)
            setError('please enter old password')
        }else if(newpass===""){
            setHasError(true)
            setError('please enter Your new Password')
        }else if(renewpass ===""){
            setHasError(true)
            setError('please enter Your new Password again')
        }else if(newpass !== renewpass){
            setHasError(true)
            setError('new password and confirm password should match !')
        }else{
            setProcessing(true)
            const { data } = await axios.post(`${URL}user/changepass`, {oldPass, newpass, userid})
            setProcessing(false)
            if(data.success){
                // success
                setSuccess(true)
            }else{
                setSuccess(false)
                setHasError(true)
                setError(data.error)
            }
        }
    }

    return (
        <div className="row">
            <div className="card col-md-5 mx-auto">
                <div className="card-header">
                    <h4>change Password</h4>
                </div>
                {hasError && <div className="alert alert-danger">
                    <strong>Error:</strong>
                    {error}
                </div>}

                {success && <div className="alert alert-success">
                    <strong>success:</strong>
                    password change successfull 
                </div>}

                <div className="card-body">
                    <div className="form-group">
                        <label>Old Password</label>
                        <input type="password" onInput={(e) => setOldPass(e.target.value)} className="form-control" />
                    </div>
                    <div className="form-group">
                        <label>New Password</label>
                        <input type="password" onInput={(e) => setNewpass(e.target.value)} className="form-control" />
                    </div>
                    <div className="form-group">
                        <label>confirm new Password</label>
                        <input type="password" onInput={(e) => setRenewpass(e.target.value)} className="form-control" />
                    </div>
                    <div className="form-group">
                        <button disabled={processing} onClick={changepass} className="btn btn-dark btn-block">
                            {processing?"processing...":"Change password"}
                            
                            </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Changepass
