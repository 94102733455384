import React from 'react';

import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom"
import Header from './components/inc/Header';
import Home from './components/Home';
import CustomerAdd from './components/content/customer/CustomerAdd';
import CustomerList from './components/content/customer/CustomerList';
import RemittanceAdd from './components/content/remittance/RemittanceAdd';
import RemittanceList from './components/content/remittance/RemittanceList';
import Login from './components/auth/Login';
import Register from './components/auth/Register';

import {useSelector} from "react-redux"
import CustomerEdit from './components/content/customer/CustomerEdit';
import CustomerView from './components/content/customer/CustomerView';
import Profile from './components/content/user/Profile';
import Verify from './components/auth/Verify';
import Changepass from './components/auth/Changepass';

function App() {
  
  let {isAuthenticated} = useSelector(state => state.auth)
  return (
    <Router>
      {isAuthenticated &&  <Header />}
      
      {!isAuthenticated &&
        <Switch>
          <Route path="/authenticate" exact component={Login} />
          <Route path="/join"  component={Register} />
          <Redirect to="/authenticate" />
        </Switch>
      }
        { isAuthenticated ?
          <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/verify"  component={Verify} />
              <Route path="/change-password"  component={Changepass} />
              <Route path="/profile"  component={Profile} />
              <Route path="/customer-add"  component={CustomerAdd} />
              <Route path="/customer-list"  component={CustomerList} />
              <Route path="/remittance-add"  component={RemittanceAdd} />
              <Route path="/remittance-list"  component={RemittanceList} />
              <Route path="/customer-edit/:id"  component={CustomerEdit} />
              <Route path="/customer-view/:id"  component={CustomerView} />
              <Redirect to="/" />
            </Switch>
          :null}

    </Router>
  );
}

export default App;
