// Your web app's Firebase configuration
import firebase from 'firebase/app' ;
import "firebase/storage"

var firebaseConfig = {
  apiKey: "AIzaSyD3gUCaHTllNs35uC7dg4MS9F4bVHbO6os",
  authDomain: "safeler-bank.firebaseapp.com",
  databaseURL: "https://safeler-bank.firebaseio.com",
  projectId: "safeler-bank",
  storageBucket: "safeler-bank.appspot.com",
  messagingSenderId: "1022054164978",
  appId: "1:1022054164978:web:4783a48da2fec14af4e36b"
  };
  // Initialize Firebase
firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();

export { storage, firebase as default }
